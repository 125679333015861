import { HeadingNarrowBoldCaps } from "@components/typography";
import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const trainerTitleStyles = css`
  margin-bottom: 0.5rem;
`;

export const TrainerTitle = styled(HeadingNarrowBoldCaps)`
  ${trainerTitleStyles}
`;

const trainerOverviewWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 1rem;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding: 2rem;
  }
`;

export const TrainerOverviewWrapper = styled.div`
  ${trainerOverviewWrapperStyles}
`;

const trainerOverviewStyles = css`
  padding-top: 1rem;
`;

export const TrainerOverviewGrid = styled(Grid)`
  ${trainerOverviewStyles};
`;
