import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { StatusDoingIcon, StatusDoneIcon, StatusLockedIcon } from "@components/Application/styles";
import CenteredSpinner from "@components/CenteredSpinner";
import { ActivityTitle } from "@components/Chapter/styles";
import CheckOutTitleWithIllustration from "@components/CheckOutTitleWithIllustration";
import { CheckOutType } from "@components/CheckOutTitleWithIllustration/CheckOutTitleWithIllustration";
import { useTrainerState } from "@components/Trainer/TrainerLayout/hooks";
import {
  TrainerIconWrapper,
  TrainerOverlayWrapper,
  TrainerResultAndButtonWrapper,
  TrainerStatusWrapper,
  TrainerStructureWrapper,
} from "@components/Trainer/TrainerSegmentStatus/styles/TrainerSegmentStatus.styled";
import { ArrowIcon, ButtonPrimaryDarkIconRight } from "@components/buttons";
import { assertIsDefined } from "@domain/utils";

import Grid from "@anwb/poncho/components/grid";

import { CheckOutGridItem, ContentWrapper, ResultsBackground } from "../styles/Trainer.styled";

type TrainerUrlProps = {
  configurationId: string;
  dynamicActivityId: string;
  segmentId: string;
};

const TrainerSegmentStatus = () => {
  const { configurationId, dynamicActivityId, segmentId } = useParams<TrainerUrlProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");
  assertIsDefined(segmentId, "Unknown segment ID");

  const { dynamicActivity } = useTrainerState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentSegmentIndex =
    dynamicActivity?.segments
      ?.sort((a, b) => a.sequence - b.sequence)
      .findIndex((segment) => segment.id === segmentId) || 0;

  if (currentSegmentIndex === undefined) {
    return <CenteredSpinner size="large" />;
  }

  const getCheckOutTitleType = () => {
    if (currentSegmentIndex === 0) {
      return CheckOutType.TRAINER_SEGMENT_STATUS_SUCCESS;
    }
    if (currentSegmentIndex % 2 === 0) {
      return CheckOutType.TRAINER_SEGMENT_STATUS_KEEP_GOING;
    }
    return CheckOutType.TRAINER_SEGMENT_STATUS_WELL_DONE;
  };

  const getSegmentIcon = (index: number) => {
    if (index === currentSegmentIndex) {
      return <StatusDoingIcon />;
    }
    if (index < currentSegmentIndex) {
      return <StatusDoneIcon />;
    }
    return <StatusLockedIcon />;
  };

  const getSegmentStatus = (index: number) => {
    if (index < currentSegmentIndex) {
      return t("trainerSegments.textStatusFinished");
    }
    if (index === currentSegmentIndex) {
      return t("trainerSegments.textStatusNext");
    }
    return null;
  };

  const goToNextPage = () => {
    navigate(`/trainers/${configurationId}/${dynamicActivityId}/intro/${segmentId}`);
  };

  return (
    <ResultsBackground>
      <ContentWrapper>
        <Grid constrainedWidth columns={{ sm: 1, md: 1, lg: 2 }}>
          <CheckOutGridItem>
            <CheckOutTitleWithIllustration checkOutType={getCheckOutTitleType()} />
          </CheckOutGridItem>
          <CheckOutGridItem>
            <TrainerResultAndButtonWrapper>
              {dynamicActivity?.segments
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((segment, index) => {
                  if (index === currentSegmentIndex) {
                    return (
                      <TrainerStructureWrapper
                        key={segment.id}
                        onClick={() => {
                          navigate(
                            `/trainers/${configurationId}/${dynamicActivityId}/intro/${segmentId}`,
                          );
                        }}
                      >
                        <TrainerOverlayWrapper>
                          <TrainerIconWrapper>{getSegmentIcon(index)}</TrainerIconWrapper>
                          <ActivityTitle>{segment.title}</ActivityTitle>
                          <TrainerStatusWrapper>{getSegmentStatus(index)}</TrainerStatusWrapper>
                        </TrainerOverlayWrapper>
                      </TrainerStructureWrapper>
                    );
                  }
                  return (
                    <TrainerStructureWrapper key={segment.id} className="no-button">
                      <TrainerOverlayWrapper>
                        <TrainerIconWrapper>{getSegmentIcon(index)}</TrainerIconWrapper>
                        <ActivityTitle>{segment.title}</ActivityTitle>
                        <TrainerStatusWrapper>{getSegmentStatus(index)}</TrainerStatusWrapper>
                      </TrainerOverlayWrapper>
                    </TrainerStructureWrapper>
                  );
                })}

              <ButtonPrimaryDarkIconRight icon={ArrowIcon.RIGHT} onClick={goToNextPage}>
                {t("trainerSegments.buttonStatusNext")}
              </ButtonPrimaryDarkIconRight>
            </TrainerResultAndButtonWrapper>
          </CheckOutGridItem>
        </Grid>
      </ContentWrapper>
    </ResultsBackground>
  );
};

export default TrainerSegmentStatus;
