import React, { useEffect, useState } from "react";

import { useHeaderState } from "@components/HeaderContextProvider";
import OnboardingNav from "@components/Onboarding/OnboardingNav";
import OnboardingSlides from "@components/Onboarding/OnboardingSlides";
import { AnimatedDiv, OnBoardingModal } from "@components/Onboarding/styles/onboardingSlide.styled";
import { OnBoardingType } from "@domain/types";
import { useSpringRef, useTransition } from "@react-spring/web";
import FirstContentSlide from "shared/onboarding/onboarding-slide-1.svg";
import SecondContentSlide from "shared/onboarding/onboarding-slide-2.svg";
import ThirdContentSlide from "shared/onboarding/onboarding-slide-3.svg";
import FourthContentSlide from "shared/onboarding/onboarding-slide-4.svg";
import FifthContentSlide from "shared/onboarding/onboarding-slide-5.svg";

export const sliderContent = [
  {
    type: OnBoardingType.CONTENT,
    title: "Welkom bij ANWB Theorie!",
    text:
      "<p>We zijn blij dat je er bent!</p>" +
      "<p>Het nieuwe online theorieplatform van de ANWB helpt je om je theorie te halen.</p>" +
      "<h4>Wist je dat...</h4>",
    list: [
      "de theorie stap voor stap wordt uitgelegd;",
      "korte video’s de stof samenvatten;",
      "je onbeperkt kan oefenen;",
      "je in de oefenmodules alle CBR vraagsoorten tegenkomt!",
    ],

    isLastSlide: false,
    image: FirstContentSlide,
  },
  {
    type: OnBoardingType.CONTENT,
    title: "Theorie makkelijk uitgelegd.",
    text:
      "We hebben de theorie in 10 hoofdstukken verdeeld. De lessen zijn kort en afwisselend zodat je de theorie beter onthoudt!We hebben de theorie in 10 hoofdstukken verdeeld.<br /><br />" +
      "<strong>De lessen zijn kort en afwisselend zodat je de theorie beter onthoudt!</strong>",
    isLastSlide: false,
    image: SecondContentSlide,
  },
  {
    type: OnBoardingType.CONTENT,
    title: "Onbeperkt oefenen met onze trainers en examens.",
    text:
      "Test je kennis bij de hoofdstuk toetsen en oefen gericht op lastige onderwerpen zoals de borden of voorrangstrainer. Sluit af met de oefenexamens. <br /><br />" +
      "Bekijk na afloop het resultaat.",
    isLastSlide: false,
    image: ThirdContentSlide,
  },
  {
    type: OnBoardingType.CONTENT,
    title: "Onze experts helpen jou met meer dan 150 video’s.",
    text:
      "Leren onderweg? Maak gebruik van de ondertitelingsknop of speel de video langzaam of juist sneller af. <br /><br />" +
      "Helemaal hoe jij het fijn vindt!",
    isLastSlide: false,
    image: FourthContentSlide,
  },
  {
    type: OnBoardingType.CONTENT,
    title: "Op ieder device leren, dat is pas handig!",
    text: "Leer je theorie thuis, onderweg of in een koffietentje. Het kan allemaal! Start ANWB Theorie op via je tablet, computer of mobiel.",
    isLastSlide: true,
    image: FifthContentSlide,
  },
];

const Onboarding = () => {
  const { showOnboarding } = useHeaderState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animationForward, setAnimationForward] = useState(true);
  const transRef = useSpringRef();
  const transitions = useTransition(
    currentSlide,
    animationForward
      ? {
          ref: transRef,
          keys: null,
          from: { opacity: 0, transform: "translate3d(100%,0,0)" },
          enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
          leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
        }
      : {
          ref: transRef,
          keys: null,
          from: { opacity: 0, transform: "translate3d(-100%,0,0)" },
          enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
          leave: { opacity: 0, transform: "translate3d(50%,0,0)" },
        },
  );

  useEffect(() => {
    transRef.start();
  }, [transRef, currentSlide]);

  useEffect(() => {
    if (showOnboarding) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [showOnboarding]);

  if (!showOnboarding) {
    return <></>;
  }

  return (
    <OnBoardingModal>
      {transitions((style, i) => {
        return (
          <AnimatedDiv style={style}>
            <OnboardingSlides content={sliderContent[i]} />
          </AnimatedDiv>
        );
      })}
      <OnboardingNav
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        setAnimationForward={setAnimationForward}
      />
    </OnBoardingModal>
  );
};

export default Onboarding;
