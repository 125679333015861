import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import NextActivityCard from "@components/Activity/ActivityCheckOut/NextActivityCard";
import { StyledTitle } from "@components/Activity/ActivityCheckOut/NextActivityCard/styles";
import {
  CheckOutGridItem,
  ContentWrapper,
  GridContainer,
  IllustrationWrapper,
  ResultsBackground,
} from "@components/Activity/ActivityCheckOut/styles";
import CenteredSpinner from "@components/CenteredSpinner";
import CheckOutTitleWithIllustration, {
  CheckOutType,
} from "@components/CheckOutTitleWithIllustration/CheckOutTitleWithIllustration";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { assertIsDefined } from "@domain/utils";
import useActivity from "@hooks/useActivity";
import useDynamicActivityConfigurations from "@hooks/useDynamicActivityConfigurations";

import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import { DynamicActivityConfigurationType } from "@topgun/shared/src/types/frontend";

type ActivityUrlProps = {
  activityId: string;
};

const ActivityCheckOut = () => {
  const { activityId } = useParams<ActivityUrlProps>();
  assertIsDefined(activityId, "Unknown activity ID");

  const { t } = useTranslation();
  const { setShowHeaderMenu, setNavigateBack, enableLeaveConfirmation, disableLeaveConfirmation } =
    useHeaderUpdater();
  const applicationSize = useApplicationSize();

  const { activity, isLastActivityInChapter, nextActivity } = useActivity(activityId);

  const chapterId = activity?.parent?.parent?.value.id;

  const { dynamicActivityConfigurations: [chapterTest] = [] } = useDynamicActivityConfigurations(
    [DynamicActivityConfigurationType.CHAPTER_TEST],
    isLastActivityInChapter ? chapterId : undefined,
  );

  useEffect(() => {
    setShowHeaderMenu(applicationSize !== "small");
  }, [setShowHeaderMenu, applicationSize]);

  useEffect(() => {
    if (chapterId) {
      setNavigateBack({
        to: `/chapters/${chapterId}`,
        icon: "cross",
      });
    }
  }, [setNavigateBack, chapterId]);

  useEffect(() => {
    enableLeaveConfirmation({
      title: t("modal.exitResultsTitle"),
      description: t("modal.exitResultsDescription"),
    });
    return () => {
      disableLeaveConfirmation();
    };
  }, [t, enableLeaveConfirmation, disableLeaveConfirmation]);

  return (
    <ResultsBackground>
      <ContentWrapper>
        {activity ? (
          <GridContainer columns={{ sm: 1, md: 1, lg: 2 }}>
            <CheckOutGridItem>
              <IllustrationWrapper>
                <CheckOutTitleWithIllustration
                  checkOutType={CheckOutType.ACTIVITY_CHECKOUT_FINISHED}
                />

                <StyledTitle>
                  {`${activity.value.sequenceLabel ?? ""} ${activity.value.title}`}
                </StyledTitle>
              </IllustrationWrapper>
            </CheckOutGridItem>
            <CheckOutGridItem>
              <NextActivityCard
                id={nextActivity?.value.id}
                title={
                  nextActivity
                    ? `${nextActivity.value.sequenceLabel ?? ""} ${nextActivity.value.title}`
                    : undefined
                }
                chapterTest={chapterTest}
              />
            </CheckOutGridItem>
          </GridContainer>
        ) : (
          <CenteredSpinner size="large" />
        )}
      </ContentWrapper>
    </ResultsBackground>
  );
};

export default ActivityCheckOut;
