import { ImageBackgroundWrapper } from "@components/Trainer/ImageHeader/styles";
import { HeadingCompressedBoldL, InteractionLabel, SubheadingM } from "@components/typography";
import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

import { AuthorImageProps } from "@topgun/shared/src/types/frontend";

type DefaultThemeProps = {
  theme: Theme;
  image?: AuthorImageProps;
  ref?: (e: HTMLDivElement) => void | null | undefined;
  style?: Record<string, string>;
};

const CHAPTER_CONTENT_WRAPPER_TOP = 100;
const ACTIVITY_STRUCTURE_HEIGHT = 40;

export const chapterContentWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: ${pxToRem(CHAPTER_CONTENT_WRAPPER_TOP)} 2rem 0 2rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding: 1rem;
  }
`;

export const ChapterContentWrapper = styled.div<DefaultThemeProps>`
  ${chapterContentWrapperStyles}
`;

export const ChapterBackgroundWrapper = styled(ImageBackgroundWrapper)<DefaultThemeProps>`
  padding: ${pxToRem(CHAPTER_CONTENT_WRAPPER_TOP)} 2rem 0 2rem;
`;

const chapterTitleWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding-top: 0;
  }
`;

export const ChapterTitleWrapper = styled.div`
  ${chapterTitleWrapperStyles}
`;

const chapterTitleStyles = ({ theme }: DefaultThemeProps) => css`
  padding: ${pxToRem(theme.spacing["500"])} 0;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding: ${pxToRem(theme.spacing["300"])} 0;
  }
`;

export const ChapterTitle = styled(HeadingCompressedBoldL)`
  ${chapterTitleStyles}
`;

const chapterNumberTagWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

export const ChapterNumberTagWrapper = styled.div`
  ${chapterNumberTagWrapperStyles}
`;

export const ChapterGrid = styled(Grid)`
  position: relative;
  z-index: 2;
`;

const chapterProgressBarWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  margin-top: 1rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

export const ChapterProgressBarWrapper = styled.div`
  ${chapterProgressBarWrapperStyles}
`;

const sectionWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin: 0;
  }
`;

export const SectionWrapper = styled.div`
  ${sectionWrapperStyles}
`;

const sectionTitleStyles = ({ theme }: DefaultThemeProps) => css`
  padding: ${pxToRem(theme.spacing["200"])} 0;
  margin-top: ${pxToRem(theme.spacing["100"])};
  margin-bottom: ${pxToRem(theme.spacing["100"])};
`;

export const SectionTitle = styled(SubheadingM)`
  ${sectionTitleStyles}
`;

const activityWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: 0.5s ease-in-out;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ActivityWrapper = styled.div`
  ${activityWrapperStyles}
`;

export const ActivityStructureWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  margin: 1rem 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  min-height: ${pxToRem(ACTIVITY_STRUCTURE_HEIGHT)};

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const ActivityOverlayWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
`;

export const ActivityTitle = styled(InteractionLabel)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const ActivityStatusWrapper = styled.div`
  margin-left: auto;
`;
