import { StyleRefreshWrapper } from "@components/Player/H5PPlayerWrapper/styles/h5p-player-wrapper.styled";
import { NavigationButtonWrapper } from "@components/Player/styles/Player.styles";
import BodyL from "@components/typography/BodyL";
import HeadingCompressedBoldL from "@components/typography/HeadingCompressedBoldL";
import { scrollbarStyling } from "@styles/scrollbar.styled";
import { customVariables } from "@theme/custom-variables";
import styled from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import segmentIntroductionBackgroundLeft from "../../../../shared/segments/segment_introduction_background_left.svg";
import segmentIntroductionBackgroundRight from "../../../../shared/segments/segment_introduction_background_right.svg";

export const TitleText = styled(HeadingCompressedBoldL)`
  margin-bottom: 2rem;
`;

export const ContentText = styled(BodyL)`
  margin: 0 0 1rem;
`;

export const SegmentIntroductionWrapper = styled(StyleRefreshWrapper)`
  position: relative;
  max-width: 50rem;
  max-height: calc(-13rem + 100vh);
  margin: 6.66rem auto 0;

  @media (max-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.lg)}) {
    max-width: 40rem;
  }
  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.md)}) {
    margin: 10rem auto 0;
    overflow: visible;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: -5rem;
      width: 100%;
      height: calc(100% + 10rem);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
    }

    &:before {
      left: -5rem;
      background-image: url(${segmentIntroductionBackgroundLeft});
      background-position: left top;
    }

    &:after {
      right: -5rem;
      background-image: url(${segmentIntroductionBackgroundRight});
      background-position: right top;
    }
  }
`;
export const SegmentIntroductionContainer = styled(StyleRefreshWrapper)`
  position: relative;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  max-height: calc(-13.5rem + 100dvh);
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${customVariables.colors.segments.bgColor};
  border: 1px solid ${customVariables.colors.segments.borderColor};
  overflow: auto;
  ${scrollbarStyling};

  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.md)}) {
    max-height: calc(-19rem + 100vh);
    margin: 1rem;
  }
`;

export const StyledButtonWrapper = styled(NavigationButtonWrapper)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin: auto auto 2rem;
`;
