import { HeadingNarrowBoldCapsStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";

export const HeadingNarrowBoldCaps = styled(Typography)<TypographyBaseProps>`
  ${HeadingNarrowBoldCapsStyles};
`;

export default HeadingNarrowBoldCaps;
