import { BodyBoldLStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";

export const BodyBoldL = styled(Typography)<TypographyBaseProps>`
  ${BodyBoldLStyles};
`;

export default BodyBoldL;
