import {
  HeadingCompressedBoldLStyles,
  HeadingCompressedBoldMStyles,
} from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

export const HeadingCompressedBoldL = styled(Typography)<TypographyBaseProps>`
  ${HeadingCompressedBoldLStyles};

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.md)}) {
    ${HeadingCompressedBoldMStyles};
  }
`;

export default HeadingCompressedBoldL;
