import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CenteredSpinner from "@components/CenteredSpinner";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import Notification from "@components/Notification";
import TrainerCard from "@components/TrainerCard";
import { BodyS } from "@components/typography";
import { MaterialType } from "@domain/types";
import useCourse from "@hooks/useCourse";
import useDynamicActivityConfigurations from "@hooks/useDynamicActivityConfigurations";
import { getNotificationStructure } from "@utils/helpers";

import Grid from "@anwb/poncho/components/grid";
import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import { DynamicActivityConfigurationType } from "@topgun/shared/src/types/frontend";

import { TrainerOverviewGrid, TrainerOverviewWrapper, TrainerTitle } from "./styles";

const TrainersOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const { clearNavigateBack, setNavigateBack } = useHeaderUpdater();

  const { course } = useCourse();
  const applicationSize = useApplicationSize();
  const { isLoading, error, dynamicActivityConfigurations } = useDynamicActivityConfigurations(
    [DynamicActivityConfigurationType.TRAINER],
    course?.value.id,
  );

  useEffect(() => {
    if (applicationSize === "small") {
      clearNavigateBack();
    } else {
      setNavigateBack({
        to: "/dashboard",
      });
    }
  }, [applicationSize, clearNavigateBack, setNavigateBack]);

  if (error) {
    const errorNotification = getNotificationStructure(MaterialType.TRAINERS);
    return (
      <Notification
        $alignNotification
        variant={errorNotification.variant}
        title={errorNotification.title}
      >
        {errorNotification.children}
      </Notification>
    );
  }

  return (
    <TrainerOverviewWrapper data-testid="trainer-overview">
      <TrainerOverviewGrid>
        <Grid.Item>
          <Grid.Title>
            <TrainerTitle>{t("trainersOverview.title")}</TrainerTitle>
            <BodyS>{t("trainersOverview.subtitle")}</BodyS>
          </Grid.Title>
        </Grid.Item>
      </TrainerOverviewGrid>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <TrainerOverviewGrid columns={{ sm: 1, md: 2, lg: 3 }}>
          {dynamicActivityConfigurations?.map((item) => (
            <TrainerCard key={`trainer-card-${item.id}`} trainer={item} />
          ))}
        </TrainerOverviewGrid>
      )}
    </TrainerOverviewWrapper>
  );
};

export default TrainersOverview;
