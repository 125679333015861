import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d88bdd8017355b069daee9ac19448a71@o4507644678569984.ingest.de.sentry.io/4508369784078416",
  environment: process.env.NODE_ENV || "development",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
