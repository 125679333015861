import {
  TYPOGRAPHY_COMPRESSED_BOLD,
  TYPOGRAPHY_SMALL_EMPHASIS_LINE_HEIGHT,
  TYPOGRAPHY_SMALL_FONT_SIZE,
} from "@utils/constants";
import styled from "styled-components";

import { PageTitle } from "@anwb/poncho/components/typography";

export const TitleSmallEmphasis = styled(PageTitle)`
  text-transform: uppercase;
  && {
    font-weight: ${TYPOGRAPHY_COMPRESSED_BOLD};
    font-size: ${TYPOGRAPHY_SMALL_FONT_SIZE}rem;
    line-height: ${TYPOGRAPHY_SMALL_EMPHASIS_LINE_HEIGHT}rem;
  }
`;

export default TitleSmallEmphasis;
