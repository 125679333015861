import { TYPOGRAPHY_COMPRESSED_BOLD, TYPOGRAPHY_FONT_CLANOT } from "@utils/constants";
import styled, { css } from "styled-components";

import Modal, {
  ModalClose,
  ModalFooter,
  ModalTitle,
  ModalWrapper,
} from "@anwb/poncho/components/modal";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

export const modalStyles = ({ theme }: DefaultThemeProps) => css`
  ${ModalWrapper} {
    padding: 2.75rem;
  }

  ${ModalClose} {
    &:focus,
    &:hover {
      color: ${theme.colors.highlight.iconHover};
    }
  }

  ${ModalTitle} {
    font-family: ${TYPOGRAPHY_FONT_CLANOT};
    font-weight: ${TYPOGRAPHY_COMPRESSED_BOLD};
    font-size: 2rem;
    line-height: 1.1;
  }

  ${ModalFooter} {
    padding-block-start: 2rem;
  }
`;

export const StyledModal = styled(Modal)`
  ${modalStyles};
`;
