import { HeadingCompressedBoldSStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";

export const HeadingCompressedBoldS = styled(Typography)<TypographyBaseProps>`
  ${HeadingCompressedBoldSStyles}
`;

export default HeadingCompressedBoldS;
