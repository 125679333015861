import { Link } from "@components/Application/styles";
import {
  BodyS,
  HeadingNarrowBoldCaps,
  HeadingNarrowMarketingBold,
  HeadingNarrowMarketingRegular,
} from "@components/typography";
import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

type DefaultThemeProps = {
  theme: Theme;
};

const viewportBreakpointXs = 500;

const dashboardWrapperStyles = () => css`
  max-width: 100vw;
`;

export const DashboardWrapper = styled.div`
  ${dashboardWrapperStyles}
`;

const dashboardContainerStyles = () => css`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 2rem 4rem;
  width: calc(100vw - 15px);
  z-index: 1;

  @media (max-width: ${pxToRem(viewportBreakpointXs)}) {
    width: 100vw;
    margin: 0;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  // Poncho component 'HorizontalScroll' doesn't allow styling from Styled Components. That's why 'data-test' is used as the CSS selector.
  // The code below removes white fadeouts on both sides of the slider.
  [data-test="horizontal-scroll"] {
    &:before,
    &:after {
      display: none;
    }
  }
`;

export const DashboardContainer = styled.div`
  ${dashboardContainerStyles}
`;

const dashboardTitleHeaderStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: column;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-bottom: 0;
  }
`;

export const DashboardTitleHeader = styled.div`
  ${dashboardTitleHeaderStyles}
`;

const dashboardGreetingStyles = ({ theme }: DefaultThemeProps) => css`
  color: #ffffff;
  margin-bottom: ${pxToRem(theme.spacing["100"])};

  > span {
    font-weight: bold;
  }
`;
export const DashboardGreeting = styled(HeadingNarrowMarketingRegular)`
  ${dashboardGreetingStyles}
`;

export const Name = styled(HeadingNarrowMarketingBold)`
  ${dashboardGreetingStyles}
`;

export const DashboardTitle = styled(HeadingNarrowBoldCaps)`
  margin-bottom: 0.5rem;
`;

export const DashboardSubTitle = styled(BodyS)`
  padding-bottom: 0.5rem;
`;

const dashboardHeaderLinkStyles = ({ theme }: DefaultThemeProps) => css`
  margin-top: auto;
  padding-bottom: 0.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    margin-top: 1rem;
  }
`;

export const DashboardHeaderLink = styled(Link)`
  ${dashboardHeaderLinkStyles}
`;

export const DashboardLink = styled(Link)`
  align-self: end;
  padding-bottom: 0.5rem;
`;

const dashboardMobileHeaderLinkStyles = ({ theme }: DefaultThemeProps) => css`
  display: none;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    display: block;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const DashboardMobileHeaderLink = styled(Link)`
  ${dashboardMobileHeaderLinkStyles}
`;

const chapterLabelStyles = ({ theme }: DefaultThemeProps) => css`
  margin-bottom: 0.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    display: none;
  }
`;

export const ChapterLabel = styled(BodyS)`
  ${chapterLabelStyles}
`;

const dashboardGridStyles = ({ theme }: DefaultThemeProps) => css`
  margin: 0 0 1rem 0;
  overflow: hidden;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-bottom: 0;
  }
`;

export const DashboardGrid = styled.div`
  ${dashboardGridStyles}
`;

const dashboardGridHeaderStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 2rem;
  width: 100%;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin-bottom: 1rem;
  }
`;

export const DashboardGridHeader = styled.div`
  ${dashboardGridHeaderStyles}
`;

const dashboardGridContentStyles = ({ theme }: DefaultThemeProps) => css`
  grid-gap: 1rem;

  display: block;
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    display: grid;
  }

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    grid-gap: 0;
  }
`;

export const DashboardGridContent = styled(Grid)`
  ${dashboardGridContentStyles}
`;

const whatsNextGridStyling = ({ theme }: DefaultThemeProps) => css`
  grid-gap: ${pxToRem(theme.spacing["300"])};

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    display: none;
  }
`;

export const WhatsNextGrid = styled(Grid)`
  ${whatsNextGridStyling}
`;

export const WhatsNextGridItem = styled(Grid.Item)`
  cursor: pointer;
`;

export const PracticeExamWrapper = styled.div`
  padding-top: 2rem;
`;

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;
