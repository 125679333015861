import { NavigationButtonIcon, SecondaryNavigationButtonIcon } from "@components/buttons";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  height: 100%;
`;

export const MobileBackButton = styled(SecondaryNavigationButtonIcon)`
  margin-inline: 1rem auto;
`;

export const MobileNextButton = styled(SecondaryNavigationButtonIcon)`
  margin-inline: auto 1rem;
`;

export const MobileNavigationButtonWrapper = styled.div`
  position: fixed;
  inset: auto 0 1rem;
  display: flex;
  z-index: 2;
  pointer-events: none;

  ${NavigationButtonIcon} {
    margin-block-start: 1rem;
    pointer-events: auto;
  }

  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

const navigationButtonWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  width: calc(100vw - 5rem);
  height: 3rem;
  display: flex;
  gap: 1rem;
  z-index: 1;
  align-items: center;
  margin: auto 0 2rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: calc(-2rem + 100vw);
    display: none;
  }

  @media screen and (orientation: landscape) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    margin: auto 0 1rem;
  }
`;

export const NavigationButtonWrapper = styled.div`
  ${navigationButtonWrapperStyles}
`;

const playerContainerStyles = ({ theme }: DefaultThemeProps) => css`
  max-height: 100vh;
  height: 100vh;
  height: 100dvh;
  min-width: 100%;
  max-width: calc(var(--unit-100vw) - 5rem);
  width: calc(var(--unit-100vw) - 5rem);
  position: absolute;
  margin: 0;
  padding: 5rem 0.5rem 0;
  overflow: hidden;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    &:before {
      content: "";
      position: absolute;
      width: 100vh;
      height: 100vh;
      top: -10%;
      bottom: 0;
      left: calc(10% - 50vh);
      transform: rotate(21deg);
      background-color: #001b3c;
    }
  }
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding-inline: 2rem;
    width: 100%;
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    padding: 6.66rem 0 0;
    margin-inline: auto;
  }

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    max-height: calc(100dvh - 5rem);
  }

  @media screen and (orientation: landscape) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    padding: 5.33rem 0.5rem 0;
  }
`;

export const PlayerContainer = styled.div`
  ${playerContainerStyles};
`;
