import { TrainerCardWrapper } from "@components/TrainerCard/styles";
import { ArrowIcon, ButtonPrimaryDark, ButtonTertiaryDark } from "@components/buttons";
import { HeadingCompressedBoldS } from "@components/typography";
import { InteractionLabel } from "@components/typography/InteractionLabel";
import { customVariables } from "@theme/custom-variables";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const nextActivityCardWrapper = ({ theme }: DefaultThemeProps) => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem;

  // padding op deze manier toegepast om ongewenste styling, die meekomt van GridContainerItemContent in het poncho GridItem component, te overrulen
  &&& {
    padding: 1.5rem;
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    &&& {
      padding: 2rem;
    }
  }
`;
export const NextActivityCardWrapper = styled.div`
  ${nextActivityCardWrapper};
`;

export const StyledTitle = styled(HeadingCompressedBoldS)`
  padding-bottom: 1rem;
`;

const borderWrapperStyles = () => css`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BorderWrapper = styled.div`
  ${borderWrapperStyles}
`;

export const ButtonSeparator = styled.div`
  border-bottom: 2px dashed ${customVariables.colors.primarySun};
  margin-inline: 1rem;
  flex-grow: 1;
`;

export const TitleAboveNavigationButtons = styled(InteractionLabel)`
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const NextActivityButton = styled(ButtonPrimaryDark).attrs({
  icon: ArrowIcon.RIGHT,
})``;

export const BackToDashboardButton = styled(ButtonTertiaryDark).attrs({
  icon: ArrowIcon.LEFT,
  iconPosition: "before",
})``;

export const ChapterTestWrapper = styled.div`
  width: 100%;

  ${TrainerCardWrapper} {
    margin-inline: auto;
  }
`;
