import { HeadingNarrowMarketingBoldStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";

export const HeadingNarrowMarketingBold = styled(Typography)<TypographyBaseProps>`
  ${HeadingNarrowMarketingBoldStyles}
`;

export default HeadingNarrowMarketingBold;
