import { FunctionComponent, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "@components/Header";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { Progress } from "@components/PlayerLayout/hooks";

import { ProgressIndicator } from "./styles/player-layout.styled";

export const PlayerLayout: FunctionComponent = () => {
  const [progress, setProgress] = useState<Progress>(undefined);

  const {
    setShowHeaderLogo,
    setShowHeaderMenu,
    clearBreadcrumbs,
    enableLeaveConfirmation,
    disableLeaveConfirmation,
  } = useHeaderUpdater();
  useEffect(() => {
    setShowHeaderLogo(false);
    setShowHeaderMenu(false);
    enableLeaveConfirmation();

    return () => {
      setShowHeaderLogo(true);
      setShowHeaderMenu(true);

      clearBreadcrumbs();
      disableLeaveConfirmation();
    };
  }, [
    setShowHeaderLogo,
    setShowHeaderMenu,
    clearBreadcrumbs,
    enableLeaveConfirmation,
    disableLeaveConfirmation,
  ]);

  return (
    <div data-testid="player-layout">
      <Header
        rightElementContent={
          progress && (
            <ProgressIndicator>
              {progress[0]} / {progress[1]}
            </ProgressIndicator>
          )
        }
        className="fullWidth"
      />

      <Outlet context={{ progress, setProgress }} />
    </div>
  );
};
