import styled from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";

export const ChapterNumberTagElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(${pxToRem("2")});
  border-radius: 1.5rem;
`;
