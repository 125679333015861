import Grid from "@anwb/poncho/components/grid";
import Spinner, { SpinnerSizes } from "@anwb/poncho/components/spinner";

interface Props {
  size?: SpinnerSizes;
}

export default function CenteredSpinner({ size }: Props) {
  return (
    <Grid justify="center" columns={1}>
      <Grid.Item>
        <Spinner size={size} />
      </Grid.Item>
    </Grid>
  );
}
