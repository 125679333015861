import { ButtonPrimaryDark, ButtonTertiaryDark } from "@components/buttons";
import { HeadingCompressedBoldM } from "@components/typography";
import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

type DefaultThemeProps = {
  theme: Theme;
};

export const GridContainer = styled(Grid)`
  place-content: center;
`;

export const amountCorrectStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  gap: 0.25em;
  justify-content: center;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: column;
  }
`;

export const AmountCorrect = styled(HeadingCompressedBoldM)`
  ${amountCorrectStyles};
`;
export const Score = styled.span<{ color?: string }>`
  color: ${({ color }) => (color as string) || "inherit"};
`;

export const buttonWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  gap: 1.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyles};
`;

export const TertiaryButton = styled(ButtonPrimaryDark)`
  flex: 1;
`;

export const PrimaryButton = styled(ButtonTertiaryDark)`
  flex: 1;
`;
