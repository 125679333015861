import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link } from "@components/Application/styles";
import Notification from "@components/Notification";

import Container from "@anwb/poncho/components/container";

import Header from "../Header/Header";

import { ErrorBoundaryWrapper } from "./styles";

type ErrorType = "genericError" | "notFound" | "licenceError" | "authError";

interface Props {
  error?: Error;
  errorType?: ErrorType;
  showHeader?: boolean;
}

const ErrorBoundaryFallback: FunctionComponent<Props> = ({
  error,
  errorType = "genericError",
  showHeader = true,
}) => {
  const { t } = useTranslation();
  const errorKey = error?.name || errorType;
  const isLicenceError = errorType === "licenceError";
  return (
    <ErrorBoundaryWrapper>
      {showHeader && <Header hideMenu={isLicenceError} />}
      <Container withConstrainedWidth>
        <Notification
          variant="info"
          title={t([`notifications.${errorKey}.title`, "notifications.genericError.title"])}
        >
          {isLicenceError ? (
            <p>
              <Trans i18nKey="notifications.licenceError.text">
                <a href={t("notifications.licenceError.url") || ""}>
                  {t("notifications.licenceError.url")}
                </a>
              </Trans>
            </p>
          ) : (
            <p>{t([`notifications.${errorKey}.text`, "notifications.genericError.text"])}</p>
          )}

          {error?.message && (
            <p>
              <em>{error.message}</em>
            </p>
          )}
        </Notification>
        {!isLicenceError && <Link href="/">{t("toHomeCta")}</Link>}
      </Container>
    </ErrorBoundaryWrapper>
  );
};

export default ErrorBoundaryFallback;
