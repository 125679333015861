import {
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";

import Activity from "@components/Activity/Activity";
import ActivityCheckOut from "@components/Activity/ActivityCheckOut";
import { AuthCallback } from "@components/AuthCallback";
import { AuthProviderWithConfig } from "@components/AuthProviderWithConfig";
import AuthenticationErrorBoundary from "@components/AuthenticationErrorBoundary";
import Chapter from "@components/Chapter";
import ChapterOverview from "@components/ChapterOverview";
import Dashboard from "@components/Dashboard";
import { PlayerLayout } from "@components/PlayerLayout";
import ProtectedLayout from "@components/ProtectedLayout";
import PublicLayout from "@components/PublicLayout";
import Register from "@components/Register";
import SignOut from "@components/SignOut";
import Trainer from "@components/Trainer";
import TrainerLayout from "@components/Trainer/TrainerLayout/TrainerLayout";
import TrainerPage from "@components/Trainer/TrainerPage";
import TrainerResult from "@components/Trainer/TrainerResult";
import TrainerSegmentIntroduction from "@components/Trainer/TrainerSegmentIntroduction/TrainerSegmentIntroduction";
import TrainerSegmentStatus from "@components/Trainer/TrainerSegmentStatus/TrainerSegmentStatus";
import TrainersOverview from "@components/TrainersOverview";
import { useReducedMotion, useTransition } from "@react-spring/web";
import { RouteWrapper } from "@routes/styles";
import ErrorBoundaryFallback from "src/components/ErrorBoundaryFallback";
import HeaderLayout from "src/components/HeaderLayout";

const PLAYER_PAGES_PATH =
  /\/page\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundaryFallback />}>
      <Route element={<PublicLayout />}>
        <Route path="/oauth/callback" element={<AuthCallback />} />
        <Route path="/register/:licenseId/:joinedVia/" element={<Register />} />
        <Route path="/sign-out" element={<SignOut />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="*" element={<Content />} />
      </Route>
    </Route>,
  ),
);

/* A page transition will be shown if returned value of current path differs from returned value for new path */
const getTransitionKey = (currentPath: string): string => {
  return currentPath.replace(PLAYER_PAGES_PATH, "");
};

function Content() {
  const location = useLocation();
  const reducedMotion = useReducedMotion();
  const key = getTransitionKey(location.pathname);
  const transitions = useTransition([key], {
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    immediate: reducedMotion === true,
  });

  return transitions((style, item) => {
    return (
      <RouteWrapper key={item} style={style}>
        <Routes>
          <Route element={<HeaderLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/chapters" element={<ChapterOverview />} />
            <Route path="/chapters/:chapterId" element={<Chapter />} />
            <Route path="/activities/:activityId/checkout" element={<ActivityCheckOut />} />
            <Route path="/trainers" element={<TrainersOverview />} />
            <Route path="/trainers/:configurationId" element={<Trainer />} />
            <Route
              path="/trainers/:configurationId/:dynamicActivityId/result"
              element={<TrainerResult />}
            />
          </Route>

          <Route element={<PlayerLayout />}>
            <Route path="/activities/:activityId" element={<Activity />} />
            <Route path="/activities/:activityId/page/:pageId" element={<Activity />} />

            <Route
              path="/trainers/:configurationId/:dynamicActivityId/"
              element={<TrainerLayout />}
            >
              <Route path="page/:pageId" element={<TrainerPage />} />
              <Route path="intro/:segmentId" element={<TrainerSegmentIntroduction />} />
              <Route path="status/:segmentId" element={<TrainerSegmentStatus />} />
            </Route>
          </Route>

          <Route
            path="/licence-error"
            element={<ErrorBoundaryFallback errorType="licenceError" />}
          />

          <Route path="*" element={<ErrorBoundaryFallback errorType="notFound" />} />
        </Routes>
      </RouteWrapper>
    );
  });
}

export default function NamedRoutes() {
  return (
    <AuthenticationErrorBoundary>
      <AuthProviderWithConfig>
        <RouterProvider router={router} />
      </AuthProviderWithConfig>
    </AuthenticationErrorBoundary>
  );
}
