import { customVariables } from "@theme/custom-variables";
import styled from "styled-components";

import Typography from "@anwb/poncho/components/typography";

export const ErrorDetails = styled(Typography).attrs({
  variant: "support-text",
})`
  padding-top: 0.5rem;
  color: ${customVariables.colors.errorDetailsText};
`;
export const SpinnerWrapper = styled.div`
  margin-top: 7rem;
`;
