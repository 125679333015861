import {
  HeadingCompressedBoldLStyles,
  HeadingCompressedBoldXLStyles,
} from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

export const HeadingCompressedBoldXL = styled(Typography)<TypographyBaseProps>`
  ${HeadingCompressedBoldXLStyles};

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.md)}) {
    ${HeadingCompressedBoldLStyles};
  }
`;

export default HeadingCompressedBoldXL;
