import { FunctionComponent } from "react";

import {
  ArrowIcon,
  ButtonPrimaryDarkIconRight,
  ButtonTertiaryDarkIconLeft,
} from "@components/buttons";

import Grid from "@anwb/poncho/components/grid";
import GridItem from "@anwb/poncho/components/grid/GridItem";
import { ModalFooter, ModalProps } from "@anwb/poncho/components/modal";

import { StyledModal } from "./styles/dialog.styled";

export const Dialog: FunctionComponent<ModalProps> = ({
  children,
  cancelButtonText,
  buttonText,
  onOpenChange,
  buttonHandler,
  ...props
}) => {
  return (
    <StyledModal variant="passive" size="l" onOpenChange={onOpenChange} {...props}>
      {children}

      <ModalFooter $isScrolled={false} $isScrollable>
        <Grid columns={2}>
          <GridItem>
            <ButtonTertiaryDarkIconLeft onClick={() => onOpenChange} icon="cross">
              {cancelButtonText}
            </ButtonTertiaryDarkIconLeft>
          </GridItem>
          <GridItem>
            <ButtonPrimaryDarkIconRight onClick={buttonHandler} icon={ArrowIcon.RIGHT}>
              {buttonText}
            </ButtonPrimaryDarkIconRight>
          </GridItem>
        </Grid>
      </ModalFooter>
    </StyledModal>
  );
};
