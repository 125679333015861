import { TransparentButtonIcon } from "@components/buttons";
import { BodyS, HeadingNarrowBoldCaps } from "@components/typography";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

type DefaultThemeProps = {
  theme: Theme;
  open?: boolean;
};

const HEADER_MENU_WIDTH = 350;

// TODO: refactor to annihilate this constant / breakpoint
export const VIEWPORT_BREAKPOINT_XS = 500;

const headerMenuWrapperStyles = () => css`
  [data-test="popover"] {
    width: 100%;
  }
`;

export const HeaderMenuWrapper = styled.div`
  ${headerMenuWrapperStyles}
`;

const headerMenuTriggerStyles = ({ theme, open }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #001b3c;
  width: ${pxToRem(HEADER_MENU_WIDTH)};
  height: 3rem;
  cursor: pointer;
  border-radius: ${open ? "1.25rem 1.25rem 0 0" : "1.25rem"};
  box-shadow: 0 6px 60px 0 rgba(0, 0, 0, 0.4);

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin: 0 auto;
  }
  @media (max-width: ${pxToRem(VIEWPORT_BREAKPOINT_XS)}) {
    width: 100%;
  }
`;

export const HeaderMenuTrigger = styled.div<{ open: boolean }>`
  ${headerMenuTriggerStyles}
`;

const headerMenuBodyStyles = ({ theme }: DefaultThemeProps) => css`
  width: ${350 - Number(theme.spacing["500"]) * 2}px;
  margin: 2rem 0;
  cursor: pointer;

  @media (max-width: ${pxToRem(VIEWPORT_BREAKPOINT_XS)}) {
    width: auto;
  }
`;

export const HeaderMenuBody = styled.div`
  ${headerMenuBodyStyles}
`;

export const HeaderMenuListLabel = styled(HeadingNarrowBoldCaps)`
  margin-bottom: 0;
  && {
    font-size: 1.8rem;
  }
`;

export const HeaderMenuLabel = styled(BodyS)`
  margin: auto 0;
`;

export const HeaderMenuRight = styled.div`
  margin-left: auto;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
`;

export const HeaderMenuIconButton = styled(TransparentButtonIcon)`
  width: auto;
  height: auto;
  max-height: 3rem;
  margin: 0 ${pxToRem("5")};
  padding: 0.8rem;

  > span {
    height: 1.4rem;
  }
`;

export const HeaderMenuFooter = styled.div`
  display: flex;
`;

export const HeaderMenuFooterButton = styled(TransparentButtonIcon)``;
