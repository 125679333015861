import HeadingCompressedBoldS from "@components/typography/HeadingCompressedBoldS";
import styled, { css } from "styled-components";

import { Theme } from "@anwb/poncho/design-tokens/theme";

import { getBackgroundPositionCssForImageWithFocalPoint } from "@topgun/author-front-end/src/utils/focalPoint";
import { AuthorImageProps } from "@topgun/shared/src/types/frontend";
import { scaleBynderImage } from "@topgun/shared/src/util/image";

type DefaultThemeProps = {
  theme: Theme;
};

type ChapterImageProps = {
  image?: AuthorImageProps;
};

export const NextChapterCardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const nextChapterImageStyling = ({ image }: DefaultThemeProps & ChapterImageProps) => css`
  background-image: linear-gradient(
      180deg,
      rgba(0, 15, 34, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 15, 34, 0.75) 100%
    ),
    url(${image && scaleBynderImage(image.url)});
  background-size: cover;
  ${getBackgroundPositionCssForImageWithFocalPoint(image)}
  background-repeat: no-repeat;
  background-blend-mode: darken;
  aspect-ratio: 451 / 250;
  border-radius: 0.75rem;
  width: 100%;
`;

export const NextChapterImage = styled.div<{ image?: AuthorImageProps }>`
  ${nextChapterImageStyling}
`;

export const ChapterImageHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
`;

export const ChapterImageTitle = styled(HeadingCompressedBoldS)``;
