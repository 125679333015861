import { BodyLStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/poncho/components/typography";

export const BodyL = styled(Typography)<TypographyBaseProps>`
  ${BodyLStyles};
`;

export default BodyL;
